<template>
  <v-container
    fluid
    class="visit-listing white-background main-listing-page pb-0"
  >
    <div>
      <v-tabs
        active-class="custom-tab-active"
        v-model="visitTab"
        background-color="transparent"
        color="cyan"
        class="visit-count custom-tab-transparent tab-sticky custom-tab"
      >
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#all"
        >
          <template v-if="count_all > 0">
            <v-badge bordered color="orange darken-4" :content="count_all">
              All
            </v-badge>
          </template>
          <template v-else
            ><v-badge bordered color="orange darken-4" content="0">
              All
            </v-badge></template
          >
        </v-tab>
         <v-tab
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#open"
        >
          <template v-if="count_open > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_open"
            >
              Open
            </v-badge>
          </template>
             <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
             Open
            </v-badge></template
          >
        </v-tab>
        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#overdue"
        >
          <template v-if="count_overdue > 0">
            <v-badge bordered color="orange darken-4" :content="count_overdue">
              Overdue
            </v-badge>
          </template>
          <template v-else
            ><v-badge bordered color="orange darken-4" content="0">
              Overdue
            </v-badge></template
          >
        </v-tab> -->
        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#today"
        >
          <template v-if="count_today > 0">
            <v-badge bordered color="orange darken-4" :content="count_today">
              Today
            </v-badge>
          </template>
          <template v-else
            ><v-badge bordered color="orange darken-4" content="0">
              Today
            </v-badge></template
          >
        </v-tab> -->
        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#tomorrow"
        >
          <template v-if="count_tomorrow > 0">
            <v-badge bordered color="orange darken-4" :content="count_tomorrow">
              Tomorrow
            </v-badge>
          </template>
          <template v-else
            ><v-badge bordered color="orange darken-4" content="0">
              Tomorrow
            </v-badge></template
          >
        </v-tab> -->
        <!-- <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#this-week"
        >
          <template v-if="count_this_week > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_this_week"
            >
              This Week
            </v-badge>
          </template>
          <template v-else
            ><v-badge bordered color="orange darken-4" content="0">
              This Week
            </v-badge></template
          >
        </v-tab> -->
        <!-- <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#upcomming"
        >
          <template v-if="count_upcomming > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_upcomming"
            >
              Upcoming
            </v-badge>
          </template>
          <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
              Upcoming
            </v-badge>
          </template>
        </v-tab> -->
        <v-tab
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#follow_up"
        >
          <template v-if="count_followup">
            <v-badge bordered color="orange darken-4" :content="count_followup">
              Follow up
            </v-badge>
          </template>
          <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
              Follow up
            </v-badge></template
          >
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#completed"
        >
          <template v-if="count_completed">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_completed"
            >
              Completed
            </v-badge>
          </template>
          <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
              Completed
            </v-badge></template
          >
        </v-tab>

        <v-tab
          v-if="false"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#investigation"
        >
          <template v-if="count_investigation">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_investigation"
            >
              Investigation
            </v-badge>
          </template>
          <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
              Investigation
            </v-badge></template
          >
        </v-tab>
        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#cancelled"
        >
          <template v-if="count_cancelled">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_cancelled"
            >
              Cancelled
            </v-badge>
          </template>
          <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
              Cancelled
            </v-badge></template
          >
        </v-tab> -->
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#require-signature"
        >
          <template v-if="count_signature">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_signature"
            >
              Require signature
            </v-badge>
          </template>
          <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
              Require signature
            </v-badge></template
          >
        </v-tab>
         <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#require-signature-sdo"
        >
          <template v-if="count_signature_sdo">
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_signature_sdo"
            >
             SDO Require signature
            </v-badge>
          </template>
          <template v-else>
            <v-badge bordered color="orange darken-4" content="0">
             SDO Require signature
            </v-badge></template
          >
        </v-tab>
        <v-tab
          v-if="follow_up_route == 1 && false"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#follow_up_open"
        >
          <template v-if="counts.follow_up > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.follow_up"
            >
              Open
            </v-badge>
          </template>
          <template v-else>Open</template>
        </v-tab>
        <v-tab
          v-if="follow_up_route == 1 && false"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#follow_up_close"
        >
          <template v-if="counts.follow_up > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.follow_up"
            >
              Close
            </v-badge>
          </template>
          <template v-else>Close</template>
        </v-tab>
        
        <v-tab
          v-if="false"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#delete-visit"
        >
          <template v-if="counts.deleted > 0">
            <v-badge bordered color="orange darken-4" :content="counts.deleted">
              Deleted
            </v-badge>
          </template>
          <template v-else>Deleted</template>
        </v-tab>

        <v-tabs-items v-model="visitTab">
          <v-tab-item value="all">
            <VisitListingTemplate
              v-on:archived="getVisitCounts"
              :is-admin="isAdmin"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="overdue">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="overdue"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="today">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="today"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="tomorrow">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="tomorrow"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="this-week">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="this-week"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="upcomming">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="upcomming"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="completed">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="completed"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="follow_up">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="follow_up"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="cancelled">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="cancelled"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="require-signature">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="require_signature"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
              <v-tab-item value="require-signature-sdo">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="require_signature_sdo"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
            <v-tab-item value="open">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="open"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="investigation">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="investigation"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="delete-visit">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="deleted"
              is-deleted
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "visit",
  mounted() {
    const route_name = this.$route.name;
    this.follow_up_route = this.$route.query.follow_up;
    if (this.follow_up_route && this.follow_up_route == 1) {
      this.title = "Follow Up";
    } else {
      this.title = "Visit";
    }
    const indexd = route_name.indexOf("admin");
    if (indexd >= 0) {
      this.isAdmin = true;
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
    this.getVisitCounts();
  },
  data() {
    return {
      title: null,
      isAdmin: false,
      pageLoading: false,
      follow_up_route: null,
      // visitTab: null,
      count_all: 0,
      count_overdue: 0,
      count_today: 0,
      count_tomorrow: 0,
      count_this_week: 0,
      count_completed: 0,
      count_followup: 0,
      count_investigation: 0,
      count_upcomming: 0,
      count_cancelled: 0,
      count_signature: 0,
      count_signature_sdo : 0,
      count_open: 0,
      counts: {
        all: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        this_week: 0,
        upcomming: 0,
        completed: 0,
        follow_up: 0,
        open: 0,
      },
    };
  },
  methods: {
    /* getVisitCounts(countObject) {
      const _this = this;
      if (_this.lodash.isEmpty(countObject) === false) {
        _this.counts = {
          all: _this.lodash.toSafeInteger(countObject.all),
          overdue: _this.lodash.toSafeInteger(countObject.overdue),
          today: _this.lodash.toSafeInteger(countObject.today),
          tomorrow: _this.lodash.toSafeInteger(countObject.tomorrow),
          this_week: _this.lodash.toSafeInteger(countObject.thisWeek),
          upcomming: _this.lodash.toSafeInteger(countObject.upcomming),
          completed: _this.lodash.toSafeInteger(countObject.completed),
          follow_up: _this.lodash.toSafeInteger(countObject.follow_up),
        };
      }
    }, */
    getVisitCounts() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "visit/counts",
          data: {
            module_type: "visit",
           is_archived: this.$route.query?.archived,
          },
        })
        .then(({ data }) => {
          _this.count_all = data.count_all;
          _this.count_overdue = data.count_overdue;
          _this.count_today = data.count_today;
          _this.count_tomorrow = data.count_tomorrow;
          _this.count_this_week = data.count_this_week;
          _this.count_completed = data.count_completed;
          _this.count_followup = data.count_followup;
          _this.count_investigation = data.count_investigation;
          _this.count_upcomming = data.count_upcomming;
          _this.count_cancelled = data.count_cancelled;
          _this.count_signature = data.count_signature;
          _this.count_signature_sdo = data.count_signature_sdo;
          _this.count_open = data.count_open;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  components: {
    VisitListingTemplate,
  },
  created() {
    if (this.$route.query && this.$route.query.tab) {
      this.visitTab = this.$route.query.tab;
    }
  },

  computed: {
    visitTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.visitTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "all";
      },
    },
  },
};
</script>
